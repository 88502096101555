import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Seo from "../components/SEO"

import { Container, Row, Col } from 'react-bootstrap'
import { description, facebook, imageRow, links } from '../styles/about.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'


const About = ({data}) => {

    return (
        <>
            <Seo title="About" />
            <Container className={description}>
                <h1> ABOUT US </h1>
                <Row>
                    <Col> 
                        <h2>Since 1988</h2>
                        <p>
                          Latuss is a company designing and manufacturing interior and 
                          exterior furniture, intended primarily for enriching living 
                          space with pronounced decorations. Our products are interesting 
                          in terms of design as well as materials and techniques we use.
                          Techniques such as weaving, gilding and hand-painting, give our 
                          products a special note. 
                        </p> 
                        <p>                           
                            Some of our older products are displayed below. For our latest, 
                            visit the <Link to="/lamps/" className={links}>Lamps</Link> page.
                        </p>
                    </Col>
                </Row>
                <Row xs={{cols: 2}} sm={{cols: 3}} className={imageRow}>
                  <Col>
                    <GatsbyImage 
                        image={data.velika.childImageSharp.gatsbyImageData} 
                        alt={"larix maxima"} 
                    />
                  </Col>
                  <Col>
                    <GatsbyImage 
                        image={data.vse.childImageSharp.gatsbyImageData} 
                        alt={"ambient"} 
                    />
                  </Col> 
                  <Col>
                      <GatsbyImage 
                        image={data.crni.childImageSharp.gatsbyImageData} 
                          alt={"larix minima"} 
                      />
                  </Col>
                </Row>
                <Row className={facebook}>
                    <Col>
                    <p> 
                        <a href="http://www.facebook.com/Latuss.eu/" className={links}> 
                            <FontAwesomeIcon icon={faFacebookSquare} />
                            <p style={{textDecoration: "none", display: "inline"}}> Facebook </p>
                        </a>
                    </p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}


export default About


export const query = graphql`{
  velika: file(relativePath: {eq: "about/velika.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        height: 400
        quality: 75
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
  vse: file(relativePath: {eq: "about/vse.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        height: 400
        quality: 75
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
  crni: file(relativePath: {eq: "about/crni.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        height: 400
        quality: 75
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
}
`